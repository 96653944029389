import { Chart } from "chart.js"
import ChartDataLabels from 'chartjs-plugin-datalabels';


const yScaleImagePlugin = {
  id: 'y_scale_image_plugin',
  beforeDraw: function(chart) {
    const { ctx, data, scales: { y }, options } = chart;
    (data.images || []).forEach((image, index) => {
      const tickImage = new Image();
      tickImage.src = image;
      const width = 40
      ctx.drawImage(tickImage, 0, y.getPixelForValue(index) - width / 2, width, width);
    })
  }
}

Chart.register(yScaleImagePlugin)
Chart.register(ChartDataLabels);