import { Controller } from "@hotwired/stimulus";
import { Chart } from "chart.js";
Chart.defaults.color = '#a6adba'
Chart.defaults.font.weight = 400
Chart.defaults.font.family = "Inter var, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"
export default class extends Controller {
  static values = {
    labels: Array,
    data: Object,
    chartId: String,
    chartType: String,
    images: Array,
    title: String,
    indexAxis: 'y',
    maxScaleIncrement: 50
  }
  static outlets = ['chart-state']

  connect() {
    const chart = this.chartStateOutlet.getChart(this.chartIdValue)
    const isMultiple = Object.values(this.dataValue)[0] instanceof Array
    if (!chart) {
      const ctx = document.getElementById(this.chartIdValue)
      this.chart = new Chart(ctx, {
        type: this.chartTypeValue,
        data: {
          labels: this.labelsValue,
          datasets: isMultiple ? 
            Object.entries(this.dataValue).map(([label, data]) => {
              return {
                label: label,
                data: data,
                borderWidth: 1,
              }
            })
            : [{
              data: Object.values(this.dataValue),
              borderWidth: 1,
            }],
          images: this.imagesValue
        },
        options: {
          indexAxis: this.indexAxisValue,
          animation: {
            delay: 100
          },
          scales: {
            x: {
              max: this.calculateMaxScaleIncrement(),
              title: {
                display: true,
                text: 'Count',
                font: {
                  size: 16,
                }
              },
            },
            y: {
              ticks: this.imagesValue.length > 0 ? {
                callback: function() {
                    return '            '
                }
              } : {}
            },
          },
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              align: 'end',
              anchor: 'end',
              font: {
                size: 16
              }
            },
            legend: {
              display: isMultiple
            },
            title: {
              text: this.titleValue,
              display: true,
              font: {
                size: 20
              }
            }
          }
        }
      })
      this.chartStateOutlet.addNewChart(this.chartIdValue, this.chart)
    } else {
      this.chart = chart
      const originalMapping = this.chart.data.labels.reduce((acc, value, index) => {
        acc[value] = index
        return acc
      }, {})
      this.chart.data.labels = this.labelsValue
      this.chart.data.images = this.imagesValue
      this.chart.options.scales.x.max = this.calculateMaxScaleIncrement()
      this.chart.data.datasets = isMultiple ? 
      Object.entries(this.dataValue).map(([label, data]) => {
        return {
          label: label,
          data: data,
          borderWidth: 1,
        }
      })
      : [{
        data: Object.values(this.dataValue),
        borderWidth: 1,
      }]
      // const meta =this.chart.getDatasetMeta(0)
      // console.log(meta.data)
      // const newMetaData = []
      // this.labelsValue.forEach((label, index) => {
      //   newMetaData[index] = meta.data[originalMapping[label]]
      // })

      // meta.data =newMetaData
      this.chart.update()
    }
  }

  calculateMaxScaleIncrement() {
    const max = Math.max(...Object.values(this.dataValue).flat())
    return Math.ceil(max / this.maxScaleIncrementValue) * this.maxScaleIncrementValue
  }
}
