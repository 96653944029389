import { Controller } from "@hotwired/stimulus";
import "choices.js/public/assets/styles/choices.min.css";
import Choices from "choices.js";
// Connects to data-controller="clears--form"

export default class extends Controller {
  static values = {
    choicesOptions: Object,
    choices: Array,
  };
  static outlets = ["choice"];

  connect() {
    this.initChoices();
    this.morphHandler = this.reInitChoices.bind(this);
    this.element.addEventListener("turbo:morph-element", this.morphHandler);
  }

  reInitChoices(event) {
    if (event.target !== this.element) return;

    this.initChoices();
  }

  initChoices() {
    this.choices = new Choices(
      this.element.querySelector("select"),
      {...(this.choicesOptionsValue || {}), classNames: { containerOuter: "choices w-full" } }
    );
    this.choices.setChoices(this.choicesValue);
    this.currentChoices = [...this.choicesValue];
  }

  disconnect() {
    const value = this.choices.getValue(true);
    this.choices.destroy();
    this.element.querySelector("select").value = value;
    this.choicesDestroyed = true;
    this.element.removeEventListener("turbo:morph-element", this.morphHandler);
  }

  removeItem(itemValue) {
    this.choices.removeActiveItemsByValue(itemValue.toString());
  }

  choiceOutletConnected(outlet) {
    // when there are existing choiceOutlet, and the currentChoices is not set yet
    if (!this.currentChoices) return;

    this.disableChoice(outlet.choiceValue);
    this.updateChoices();
  }

  choiceOutletDisconnected(outlet) {
    if (this.choicesDestroyed) return;

    this.enableChoice(outlet.choiceValue);

    this.updateChoices();
  }

  disableChoice(targetChoice) {
    this.currentChoices.forEach((choice) => {
      if (choice.value === targetChoice.value) {
        choice.disabled = true;
      }
    });
  }

  enableChoice(targetChoice) {
    this.currentChoices.forEach((choice) => {
      if (choice.value === targetChoice.value) {
        choice.disabled = false;
      }
    });
  }

  updateChoices() {
    this.choices.setChoices(this.currentChoices, "value", "label", true);
  }
}
