import { Controller } from "@hotwired/stimulus";
import { post } from '@rails/request.js'

export default class extends Controller {
  static outlets = ['chart-state']
  connect() {
    this.chart = this.chartStateOutlet.getChart(this.chartIdValue)
  }

  download() {
    this.chartStateOutlet.getCharts().forEach(chart => {
      this.downloadChart(chart)
    })
    // if (this.chart) {
    //   const response = await post(this.downloadUrlValue, { body: JSON.stringify({ data: this.chart.toBase64Image()}) })
    //   if (response.ok) {
    //     // const link = document.createElement("a");
    //     // link.download =  "chart.png";
    //     // link.href = response.body
    //     // link.click();
    //     const text = await response.text
    //     const a = document.createElement('a');
    //     a.style.display = 'none';
    //     a.href = text
    //     // the filename you want
    //     a.download = 'todo-1.png';
    //     document.body.appendChild(a);
    //     a.click();

    //     // Do whatever do you want with the response body
    //     // You also are able to call `response.html` or `response.json`, be aware that if you call `response.json` and the response contentType isn't `application/json` there will be raised an error.
    //   }
    // }
  }

  downloadChart(chart) {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = chart.toBase64Image()
    // the filename you want
    a.download = `${chart.options.plugins.title.text}.png`;
    document.body.appendChild(a);
    a.click();
  }
}