import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  connect() {
    this.charts = {}
  }

  addNewChart(chartId, chart) {
    this.charts[chartId] = chart
  }

  getChart(chartId) {
    return this.charts[chartId]
  }

  getCharts() {
    return Object.values(this.charts)
  }

  disconnect() {
    for (const chartId in this.charts) {
      this.charts[chartId].destroy()
      delete this.charts[chartId]
    }
  }
}